import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class CustomToastrService {
  constructor(
    private httpClient: HttpClient,
    public translateService: TranslateService,
    public tsrtService: ToastrService
  ) {}

  success(message: string) {
    this.translateService.get(message).subscribe((mes: string) => {
      this.tsrtService.success(mes);
    });
  }

  error(message: string) {
    this.translateService.get(message).subscribe((mes: string) => {
      this.tsrtService.error(mes);
    });
  }

  info(message: string) {
    this.translateService.get(message).subscribe((mes: string) => {
      this.tsrtService.info(mes);
    });
  }

  warning(message: string) {
    this.translateService.get(message).subscribe((mes: string) => {
      this.tsrtService.warning(mes);
    });
  }

  confirmDelete(url: string, values: any) {
    // alertify.confirm("aa", "bb", () => { this.deleteRequest(url,values); }
    // , () => { this.error("hata")});
    //alertify.confirm('Confirm Message', function(){ alertify.success('Ok') }, function(){ alertify.error('Cancel')});
    //
    // alertify.confirm('Delete Message',this.deleteRequest(url,values),this.error("Hata"));
    //
    // toastrs
    //   .alert()
    //   .setting({
    //     label: "Agree",
    //     message: "This dialog is ",
    //     onok: this.delete(url, values),
    //   })
    //   .show();
  }

  delete(url: string, values: any) {
    this.httpClient.request("delete", url, values);
  }
}

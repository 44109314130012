import { Component, OnInit } from "@angular/core";
import { Router} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../../admin/login/services/auth.service";


declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  claim: string;
}
export const ADMINROUTES: RouteInfo[] = [
  {
    path: "/user",
    title: "Users",
    icon: "how_to_reg",
    class: "",
    claim: "GetUsersQuery",
  },
  {
    path: "/group",
    title: "Groups",
    icon: "groups",
    class: "",
    claim: "GetGroupsQuery",
  },
  {
    path: "/operationclaim",
    title: "OperationClaim",
    icon: "local_police",
    class: "",
    claim: "GetOperationClaimsQuery",
  },
  {
    path: "/language",
    title: "Languages",
    icon: "language",
    class: "",
    claim: "GetLanguagesQuery",
  },
  {
    path: "/translate",
    title: "TranslateWords",
    icon: "translate",
    class: "",
    claim: "GetTranslatesQuery",
  },
  {
    path: "/log",
    title: "Logs",
    icon: "update",
    class: "",
    claim: "GetLogDtoQuery",
  },
  {
    path: "/ogun",
    title: "Ogun",
    icon: "update",
    class: "",
    claim: "GetOgunsQuery",
  },
  {
    path: "/tasima",
    title: "Tasima",
    icon: "update",
    class: "",
    claim: "GetTasimasQuery",
  },
];

export const MANAGERROUTES: RouteInfo[] = [
  {
    path: "/sirketcard",
    title: "SirketCard",
    icon: "update",
    class: "",
    claim: "GetSirketCardsQuery",
  },
  {
    path: "/sirketcardtipi",
    title: "SirketCardTipi",
    icon: "update",
    class: "",
    claim: "GetSirketCardTipisQuery",
  },
  {
    path: "/oguntakiponay",
    title: "OguntakipOnay",
    icon: "update",
    class: "",
    claim: "GetOgunTakipsQuery",
  },
  {
    path: "/usersirketcard",
    title: "UserSirketCard",
    icon: "update",
    class: "",
    claim: "GetUserSirketCardsQuery",
  },
  {
    path: "/oguntakipalluser",
    title: "OguntakipAllUser",
    icon: "update",
    class: "",
    claim: "GetOgunTakipAllUserQuery",
  },
  {
    path: "/oguntotalpaymetalluser",
    title: "OgunTakippaymentAllList",
    icon: "update",
    class: "",
    claim: "OgunTakipUserSummaryListDtoQuery",
  },
  {
    path: "/personelpaymet",
    title: "PersonelPaymet",
    icon: "update",
    class: "",
    claim: "GetPersonelPaymentsQuery",
  },
  {
    path: "/personelpaymentall",
    title: "PersonelPaymentAll",
    icon: "update",
    class: "",
    claim: "GetPaymentOgunTakipAllUserSourceQuery",
  },
  {
    path: "/tasimatakiponay",
    title: "TasimaTakipOnay",
    icon: "update",
    class: "",
    claim: "GetAprovallTasimaUserAllQuery",
  },
  {
    path: "/bomitem",
    title: "Bomitem",
    icon: "update",
    class: "",
    claim: "GetBomitemsQuery",
  },

  {
    path: "/warehouse",
    title: "Warehouse",
    icon: "update",
    class: "",
    claim: "GetWarehousesQuery",
  },
  {
    path: "/proje",
    title: "Projeler",
    icon: "update",
    class: "",
    claim: "GetVendoorProjesQuery",
  },
  {
    path: "/vendoordepostok",
    title: "DepoStok",
    icon: "update",
    class: "",
    claim: "GetVendoorDepoStoksQuery",
  },
  {
    path: "/vendoorstokenvanter",
    title: "VendoorStokEnvanter",
    icon: "update",
    class: "",
    claim: "GetVendoorStokEnvantersQuery",
  },

  {
    path: "/vendoorenvantertakip",
    title: "VendoorEnvanterTakip",
    icon: "update",
    class: "",
    claim: "GetVendoorStokEnvantersQuery",
  },

  {
    path: "/customervendoor",
    title: "Vendoor",
    icon: "update",
    class: "",
    claim: "GetCustomerVendoorsQuery",
  },

  {
    path: "/customersite",
    title: "Sahalar",
    icon: "update",
    class: "",
    claim: "GetCustomeSitesQuery",
  },
  {
    path: "/workorder",
    title: "WorkOrder",
    icon: "update",
    class: "",
    claim: "GetWorkOrdersQuery",
  },
  {
    path: "/workordertype",
    title: "WorkOrderType",
    icon: "update",
    class: "",
    claim: "GetWorkOrderTypesQuery",
  },

  {
    path: "/workorderpriority",
    title: "wWrkorderpriority",
    icon: "update",
    class: "",
    claim: "GetWorkOrderPrioritiesQuery",
  },
];

export const USERROUTES: RouteInfo[] = [

  {
    path: "/aractakip",
    title: "AracTakip",
    icon: "update",
    class: "",
    claim: "GetAracTakipQuery",
  },
  {
    path: "/aracbakim",
    title: "AracBakim",
    icon: "update",
    class: "",
    claim: "GetAracBakimQuery",
  },
  {
    path: "/oguntakip",
    title: "Oguntakip",
    icon: "update",
    class: "",
    claim: "GetOgunTakipQuery",
  },
  {
    path: "/tasimatakip",
    title: "Tasimatakip",
    icon: "local_police",
    class: "",
    claim: "GetOgunsQuery",
  },
  {
    path: "/personelpaymetuser",
    title: "PersonelPaymetUser",
    icon: "local_police",
    class: "",
    claim: "GetPaymentUserSourceQuery",
  },
];

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.css"],
    
   
})
export class SidebarComponent implements OnInit {
  adminMenuItems: any[];
  userMenuItems: any[];
  managerMenuItems: any[];

  constructor(
    private router: Router,
    private authService: AuthService,
    public translateService: TranslateService
  ) {}

  ngOnInit() {
    this.adminMenuItems = ADMINROUTES.filter((menuItem) => menuItem);
    this.userMenuItems = USERROUTES.filter((menuItem) => menuItem);
    this.managerMenuItems = MANAGERROUTES.filter((menuItem) => menuItem);

    var lang = localStorage.getItem("lang") || "tr-TR";
    this.translateService.use(lang);
  }

  // isMobileMenu() {
  //     if ($(window).width() > 991) {
  //         return false;
  //     }
  //     return true;
  // };

  checkClaim(claim: string): boolean {
    return this.authService.claimGuard(claim);
  }
  ngOnDestroy() {
    if (!this.authService.loggedIn()) {
      this.authService.logOut();
      this.router.navigateByUrl("/login");
    }
  }
}
